import React, { useState } from 'react';
import {
  Input, Button, VStack, FormControl, FormLabel, Heading, Stack, useBreakpointValue
} from '@chakra-ui/react';

const SubscribeContainer = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [local, setLocal] = useState('');
    const [message, setMessage] = useState('');
    const [comments, setComments] = useState('');

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(email, phone, city, local, message, comments);
        // Form submission logic here
    };

    // Adjust form control width based on the viewport size
    const formControlWidth = useBreakpointValue({ base: "340px", md: "520px" });

    return (
        <Stack
          spacing={4}
          direction="column"
          id="newsletter"
          alignItems="center"
          marginTop="100px"
          marginBottom="50px"
          px={4}
        >
            <Heading
              color="#142A2E"
              textAlign="center"
              fontFamily="'Quicksand', sans-serif"
              mb="30px"
              size={useBreakpointValue({ base: 'md', md: 'lg' })}
            >
                Envie d'ouvrir une Kazdalerie ?<br />
            </Heading>

            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    {/* Example FormControl - repeat the pattern for each input */}
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="email" mb={0} w="40%">
                            Adresse Email:
                        </FormLabel>
                        <Input
                            id="email"
                            value={email}
                            onChange={handleInputChange(setEmail)}
                            placeholder=""
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="phone" mb={0} w="40%">
                            telephone:
                        </FormLabel>
                        <Input
                            id="telephone"
                            value={phone}
                            onChange={handleInputChange(setPhone)}
                            placeholder=""
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="email" mb={0} w="40%">
                            ville souhaitée:
                        </FormLabel>
                        <Input
                            id="city"
                            value={city}
                            onChange={handleInputChange(setCity)}
                            placeholder=""
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="local" mb={0} w="40%">
                            local:
                        </FormLabel>
                        <Input
                            id="local"
                            value={local}
                            onChange={handleInputChange(setLocal)}
                            placeholder=""
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="message" mb={0} w="40%">
                        message:
                        </FormLabel>
                        <Input
                            id="message"
                            value={message}
                            onChange={handleInputChange(setMessage)}
                            placeholder=""
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" w={formControlWidth}>
                        <FormLabel htmlFor="comments" mb={0} w="40%">
                            comments:
                        </FormLabel>
                        <Input
                            id="comments"
                            value={comments}
                            onChange={handleInputChange(setComments)}
                            placeholder=""
                        />
                    </FormControl>
                    <Button type="submit" size="md" bg="#142A2E" color="white" onClick={handleSubmit}>
                        Soumettre
                    </Button>
                </VStack>
            </form>
        </Stack>
    );
};

export default SubscribeContainer;
