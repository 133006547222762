import React, { Fragment } from 'react';
import { Heading, Text, Stack, Flex } from '@chakra-ui/layout';
import food from '../../../images/food.jpg'
import ButtonsContainer from './ButtonsContainer';
import CounterInfo from './CounterInfo';

export default function Seccion1() {

    const userScreen = window.screen.width
    const nonAnimatedScreen = 1280

    return (
        <Fragment>
            <Flex
                id="home"
                className="smt"
                position="relative"
                bgImg={`url(${food})`}
                color="white"
                w="full"
                h="740px"
                marginBottom="20px"
                bgPosition={['center', 'center', 'center', 'right', 'right']}
                bgSize="cover"

                data-aos={userScreen > nonAnimatedScreen ? 'fade-left' : 'none'}
                data-aos-delay={userScreen > nonAnimatedScreen ? '50' : 'none'}
                data-aos-duration={userScreen > nonAnimatedScreen ? '1000' : 'none'}
                data-aos-easing={userScreen > nonAnimatedScreen ? 'ease-in-out' : 'none'}
            >
                <Stack
                    direction={"column"}
                    textColor="#142A2E"
                    textAlign={['center', 'center', 'center', 'left', 'left']}
                    pl={['0px', '0px', '0px', '90px', '90px']}
                    cursor="pointer"

                    data-aos="fade-right"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <Heading fontFamily="'Quicksand', sans-serif" mt="194px" as="h1" size='3xl' mb="26px">
                    La Kazdalerie,<br />
                    c'est quoi ? <br />
                    </Heading>
                    <Text as="span" textColor="gray.600" mb="26px !important" fontSize="18px" lineHeight="34px" px={['40px', '40px', '20px', '0px', '0px']}>
                    Entre tradition et modernité, La Kazdalerie se veut être une marque originale tout en <br/> mettant à l’honneur ses origines maghrébines.

L’utilisation du Msemen en pain, <br/>  n’a rien d’anodin, il s’agit là de rendre hommage à la cuisine nord-africaine tout en <br/> faisant découvrir ce joyau culinaire au travers de recettes originales et copieuses.<br/>

Parfois servi au petit-déjeuner ou encore à l’heure du thé, nous pensons que le Msemen <br/> tire le maximum de son potentiel lorsqu’il sert de base à un sandwich consistant et savoureux.
                    </Text>
                </Stack>
            </Flex >
            <CounterInfo />
        </Fragment >
    );
}