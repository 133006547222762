import React, { Fragment } from 'react';
import { Center, Flex } from '@chakra-ui/layout';

export default function FullIngredientsInfo({ }) {

    return (
        <Fragment>
            <Flex id="follow" mt="146px" marginBottom="30px"  px={['0px', '0px', '0px', '121px', '121px']} textAlign={['center', 'center', 'center', 'left', 'left']}
>
                <Center gridGap="40px" flexDirection={['column', 'column', 'column', 'row', 'row']}>
                <iframe src='https://widgets.sociablekit.com/instagram-feed/iframe/25393606' frameborder='0' width='1000' height='600'></iframe>
                </Center>
            </Flex>
        </Fragment>
    );
}