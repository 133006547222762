import { Image } from '@chakra-ui/image';
import { Box, Center, Flex, Heading, Text } from '@chakra-ui/layout';
import React, { Fragment } from 'react';
import Btn from '../../Btn';
import food2 from '../../../images/menu.svg'


export default function menu() {

    const userScreen = window.screen.width
    const nonAnimatedScreen = 1280

    return (
        <Fragment>
            <Flex className="smt" id="aboutUs"  textAlign={['center', 'center', 'center', 'left', 'left']} px={['0px', '0px', '0px', '121px', '121px']}>
                <Center flexDirection={['column', 'column', 'column', 'row', 'row']} gridGap="30px">
                    <Box
                        w="50%"
                        data-aos="fade-right"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                    >
                        <Image src={food2} objectFit="cover" alt="food" borderRadius="lg" />
                    </Box>
                    <Box
                        w={['100%', '100%', '100%', '60%', '60%']}

                        data-aos={userScreen > nonAnimatedScreen ? 'fade-left' : 'none'}
                        data-aos-delay={userScreen > nonAnimatedScreen ? '50' : 'none'}
                        data-aos-duration={userScreen > nonAnimatedScreen ? '1000' : 'none'}
                        data-aos-easing={userScreen > nonAnimatedScreen ? 'ease-in-out' : 'none'}
                    >
                        <Heading fontFamily="'Quicksand', sans-serif" as="h2" size="xl" textColor="#142A2E" lineHeight="48px">
                        L’ambition de la Kazdalerie est de démocratiser ce produit au travers d’une franchise utilisant des produits frais et un savoir-faire unique.
                        </Heading>
                        <Text textColor="gray.600" my="40px" fontSize="19px" lineHeight="30px" whiteSpace="pre-wrap">
                        Marocains, Algériens, Tunisiens, tous ont leur propre façon de le faire, <br/> et aujourd’hui c’est à la Kazdalerie de tous les rassembler autour de nos recettes.
                        </Text>
                    </Box>
                </Center>
            </Flex>
        </Fragment>
    );
}