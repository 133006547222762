import React, { Fragment } from 'react';
import SubscribeContainer from './SubscribeContainer';

export default function Section3() {

    return (
        <Fragment>
            <SubscribeContainer /> 
        </Fragment >
    );
}