import React, { Fragment } from 'react';
import { Link, Text } from '@chakra-ui/layout';
import Btn from './Btn';

function NavHeader({ data }) {
    return (
        <Fragment>
            {data.map(e =>
                <Link href={e.url} textDecoration="none !important" mx="12px" key={`${e.url}${Math.random}`}>
                    <Text as="b"  >
                        {e.title}
                    </Text>
                </Link>
            )}
        </Fragment>
    );
}

export default NavHeader;